<template>
  <div
    class="w-full md:min-h-screen h-full  register-container  grid place-items-center "
  >
    <div class="w-10/12 container-register  pb-4 mx-auto">
      <transition name="slideUp" mode="out-in">
        <div class="register-form" v-if="showMessage" key="form">
          <h2
            class="text-lg text-center lg:text-left md:text-2xl font-semibold mt-6 lg:mt-0"
          >
            Reset your passsword
          </h2>

          <Input
            type="email"
            width="w-full"
            placeholder="What's your email?"
            class=" mt-14"
            v-model="email"
            errorText="Please enter a valid email address"
            :validation="rules.email"
            @valid="emailValid = $event"
          />

          <Button
            text="Confirm email"
            class=" mt-12"
            @click="initiatePasswordReset"
            :shadow="true"
            width="w-full"
            :loading="emailLoading"
            :disabled="disableButton"
          />
          <div class="mt-10">
            <p class="text-sm text-center text-grey font-semibold">
              Already have an Account?
              <router-link to="/login" class="text-black">Sign In</router-link>
            </p>
          </div>
        </div>
        <div class="message  h-full" v-else key="message">
          <h2
            class="text-lg text-center lg:text-left md:text-2xl font-semibold mt-6"
          >
            <span class="text-grey"> Just to be sure.</span> Are the details
            below yours?
          </h2>
          <p class="text-center lg:text-left text-grey mt-2">
            We sent a secret code to your email {{ email }}. Kindly provide it
            below.
          </p>
          <div class="relative">
            <Input
              type="number"
              width="w-full"
              placeholder="Enter OTP"
              class=" mt-14"
              v-model="otp"
              errorText="OTP must be 6 characters"
              :validation="otprules.otp"
              @valid="otpValid = $event"
            />
            <Button
              v-if="showResendButton"
              text="Resend OTP"
              class="resend-otp z-10"
              background="bg-lightPurple"
              color="text-brandPurple"
              p="px-2.5 py-1"
              fontSize="text-sm"
              radius="rounded-3xl"
              width="w-32"
              :loading="resendLoading"
              @click="resendOtp"
            />
          </div>

          <Button
            text="Verify email"
            class=" mt-8 md:mt-16"
            @click="sendOtp"
            :shadow="true"
            width="w-full"
            :loading="loading"
            :disabled="disableOtpButton"
          />
          <div class="mt-2 md:mt-10">
            <!-- <p class="text-sm text-center text-grey font-semibold">
              I don’t have access to this numbers
            </p> -->
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { VERIFY_OTP } from "@/utils/api/auth.js";
import { mapActions, mapState } from "vuex";
import Input from "../../../UI/Input";
import Button from "@/UI/Button";
export default {
  components: {
    Input,
    Button,
  },
  data() {
    return {
      email: "",
      otp: "",
      showMessage: true,
      loading: false,
      emailLoading: false,
      resendLoading: false,
      emailValid: false,
      otpValid: false,
      showResendButton: false,
    };
  },
  computed: {
    ...mapState({
      id: (state) => state.auth.password_id,
    }),
    rules() {
      return {
        email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email
        ),
      };
    },
    otprules() {
      return {
        otp: this?.otp?.length === 6,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableOtpButton() {
      return Object.values(this.otprules).includes(false);
    },
  },
  mounted() {
    this.handleDelayOtpButton();
  },
  methods: {
    ...mapActions("auth", [
      "forgotPassword",
      "verifyPasswordResetOtp",
      "setOtpId",
    ]),
    ...mapActions("notification", ["showAlert"]),
    initiatePasswordReset() {
      this.emailLoading = true;
      this.forgotPassword({
        username: this.email,
      })
        .then(() => {
          this.showMessage = false;
          this.emailLoading = false;
        })
        .catch(() => {
          this.emailLoading = false;
        });
    },
    sendOtp() {
      this.loading = true;

      this.verifyPasswordResetOtp({
        passwordResetId: this.id,
        otp: this.otp,
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resendOtp() {
      this.resendLoading = true;
      VERIFY_OTP(this.id)
        .then((res) => {
          this.resendLoading = false;
          this.setOtpId(res.data.data.id);
          this.showAlert({
            display: true,
            type: "success",
            description: `A new otp has been sent to your email address`,
          });
          this.showResendButton = false;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.resendLoading = false;
        });
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 60000);
    },
  },
};
</script>
<style scoped>
@import "../../../assets/styles/animation.css";
/* .register-container {
    min-height: 100vh;
  } */
.register-container h3 {
  font-size: 35px;
}
.register-container p {
  line-height: 30.15px;
}
.container-register {
  max-width: 33.831rem;
}
.resend-otp {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
