<template>
  <ForgotPassword />
</template>
<script>
import ForgotPassword from "@/components/Auth/ForgotPassword";
export default {
  components: {
    ForgotPassword,
  },
};
</script>
